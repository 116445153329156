import React from 'react'

// ===========================================
// Don't use this hook in multiple components!
// ===========================================

export type OracleStatus = 'active' | 'inactive' | 'loading'

export interface UseOracleStatusOptions {
  // Url of endpoint returning update timestamp
  url: string

  // Time between consecutive requests
  interval: number

  // Time after which oracle is considered dead
  timeout: number
}

export interface UseOracleStatusState {
  // Indicating whether oracle is active or not
  status: OracleStatus

  // Last time oracle has connected to blockchain
  updateTimestamp: number | null
}

export type UseOracleStatus = (options: UseOracleStatusOptions) => UseOracleStatusState

const getUpdateTimestamp = async (url: string): Promise<number | null> => {
  try {
    const res = await fetch(url)

    if (res.status !== 200) {
      throw new Error('Oracle status request returned invalid status')
    }

    const data = await res.json()
    return data.updateTimestamp ?? null
  } catch (e) {
    console.error(e)
    return null
  }
}

// Returns current status of the oracle
export const useOracleStatus: UseOracleStatus = (options) => {
  const [timestamp, setTimestamp] = React.useState<number | null>(null)

  React.useEffect(() => {
    getUpdateTimestamp(options.url).then(setTimestamp)

    const handle = setInterval(() => {
      getUpdateTimestamp(options.url).then(setTimestamp)
    }, options.interval)

    return () => clearInterval(handle)
  }, [options.url, options.interval])

  const getStatus = (): OracleStatus => {
    if (!timestamp) return 'loading'

    const active = timestamp > Date.now() - options.timeout

    return active ? 'active' : 'inactive'
  }

  return { status: getStatus(), updateTimestamp: timestamp }
}
