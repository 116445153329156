import React from 'react'
import CardWrapper from '../Components/CardWrapper'
import { Typography } from '@mui/material'
import { useInput } from '../Providers/Input/InputProvider'

// Prices =================================

// Transfer key => 0.001 (assuming without account script)

// Transfer tokens => 0.001 (assuming without account script)

// Device interaction => 0.005 (assuming without account script)

// Create device => (assuming with account script)
//// transfer for fees 0.005
//// set script 0.01
//// data entries for device 0.005
//// data entry for supplier 0.005

// Create key => (assuming with account script)
//// issue NFT token 0.005
//// add key to device via invoke 0.009

// Update device => 0.009

// Set up supplier
//// transfer tokens for fees 0.001
//// set script 0.01
//// data entries 0.005
//// dapp father call??? 0.009

// ========================================

export type GetExpenseFunction = (value: number) => string

const getExpenseFunctions: GetExpenseFunction[] = [
  (val) => `Create ${Math.floor(val / 0.025)} devices`,
  (val) => `Create ${Math.floor(val / 0.014)} keys`,
  (val) => `Set up a supplier account ${Math.floor(val / 0.025)} times`,
  (val) => `Update ${Math.floor(val / 0.009)} devices`,
  (val) => `Transfer a key ${Math.floor(val / 0.001)} times`,
  (val) => `Interact with a device ${Math.floor(val / 0.005)} times`,
  (val) => `Transfer tokens ${Math.floor(val / 0.001)} times`
]

const ExpensesExample: React.FC = () => {
  const { output } = useInput()

  const header = `${output?.toFixed(2) || '0'} skeyUSD is equal to ${
    output * 10 ** 8
  } skeylets. It is enough to:`

  return (
    <CardWrapper>
      <Typography variant="body2" component="span" lineHeight={1.6}>
        <span>{header}</span>
        <ul style={{ transform: 'translate(-10px)' }}>
          {getExpenseFunctions.map((func, index) => (
            <li key={index}>{func(output)}</li>
          ))}
        </ul>
      </Typography>
    </CardWrapper>
  )
}

export default ExpensesExample
