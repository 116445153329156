import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Chip, Typography } from '@mui/material'
import { useEth } from '../Providers/Eth/EthProvider'
import { useWvs } from '../Providers/Wvs/WvsProvider'
import CardWrapper from './CardWrapper'

export interface WalletCardProps {
  addr?: string
  bc: 'eth' | 'skey' | 'eskey'
}

const addrCharsVisible = 8

const WalletCard: React.FC<WalletCardProps> = (props) => {
  const [balance, setBalance] = useState<any>()
  const eth = useEth()
  const { publicState, userBalance: userWvsBalance } = useWvs()

  useEffect(() => {
    const fetchBalance = async () => {
      switch (props.bc) {
        case 'eth': {
          const res = await eth.service.balance(eth.metamask.account!)
          setBalance(parseFromEth(res))
          break
        }
        case 'eskey': {
          const value = (Number(eth.userTokenBalance) / 10 ** 8).toFixed(2)
          setBalance(value)
          break
        }
      }
    }

    const parseFromEth = (amount: string) => {
      const num = Number(amount)

      return num / 10 ** 18
    }

    fetchBalance()
      .then(() => {})
      .catch((ex) => {})
    // eslint-disable-next-line
  }, [eth, publicState])

  const addr = () => {
    return (
      props.addr ??
      (props.bc === 'skey' ? publicState?.account?.address : eth.metamask.account) ??
      'Not connected'
    )
  }

  const dispAddr = (addr: string, len: number) => {
    if (addr.length < len * 2) {
      return addr
    }
    return addr.slice(0, addrCharsVisible) + '...' + addr.slice(-addrCharsVisible)
  }

  const parseFromWvs = (num: number) => {
    return (num / 10 ** 8).toFixed(8)
  }

  const name = () => {
    switch (props.bc) {
      case 'eth':
        return 'ETH'
      case 'skey':
        return 'SKEY'
      case 'eskey':
        return 'SKEY ERC20'
    }
  }

  const ethIcon = () => {
    return (
      <img
        src="/assets/images/eth.svg"
        alt="logo"
        style={{ width: 20, marginRight: 12 }}
      />
    )
  }

  const skeyIcon = () => {
    return (
      <img
        src="/assets/images/logo.svg"
        alt="logo"
        style={{ width: 40, marginRight: 12 }}
      />
    )
  }

  const eskeyIcon = () => {
    return (
      <>
        <img
          src="/assets/images/logo.svg"
          alt="logo"
          style={{ width: 40, marginRight: 12 }}
        />
        <Box position="relative" width={0} height={0}>
          <img
            src="/assets/images/eth.svg"
            alt="logo"
            style={{ width: 12, position: 'absolute', left: -18, top: 4 }}
          />
        </Box>
      </>
    )
  }

  const iconStyle: React.CSSProperties = {
    minWidth: 60,
    // height: 30,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto 0'
  }

  return (
    <CardWrapper>
      <Box display={'flex'} flexDirection={'row'}>
        <Box style={iconStyle}>
          {props.bc === 'eth' && ethIcon()}
          {props.bc === 'skey' && skeyIcon()}
          {props.bc === 'eskey' && eskeyIcon()}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '8px'
          }}
        >
          <Box marginBottom={1} sx={{ marginLeft: '5px' }}>
            <Typography variant="body2" textAlign="center">
              {props.bc === 'skey' ? parseFromWvs(userWvsBalance) : balance ?? 0} {name()}
            </Typography>
          </Box>
          <abbr title={addr()} style={{ textDecoration: 'none' }}>
            <Chip
              style={{ fontFamily: 'monospace' }}
              label={dispAddr(addr(), addrCharsVisible)}
              variant="outlined"
            />
          </abbr>
        </Box>
      </Box>
    </CardWrapper>
  )
}

export default WalletCard
