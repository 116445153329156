import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material'
import { useInput } from '../Providers/Input/InputProvider'

export interface ConfirmationDialogProps {
  open: boolean
  onClose?: () => void
  onContinue?: () => void
  fee?: number | null
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { input, output } = useInput()

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Confirm swap</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          You are going to exchange {input} SKey tokens on ETH network to{' '}
          {output.toFixed(4)} SKey tokens on SKey network. You need to accept a
          transaction request displayed by metamask.{' '}
          {props.fee
            ? `Transaction fee will be aproximately ${props.fee?.toFixed(8)} ETH.`
            : ''}
        </DialogContentText>
        <DialogActions>
          <Button onClick={props.onClose} color="error">
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.onClose?.()
              props.onContinue?.()
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationDialog
