import { getNetworkName } from '../Common/Networks'
import { EthState, useEth } from '../Providers/Eth/EthProvider'
import { useInput } from '../Providers/Input/InputProvider'
import { useWvs, WvsState } from '../Providers/Wvs/WvsProvider'

export interface InfoItem {
  status: 'success' | 'error'
  text: string
}

export interface Info {
  messages: InfoItem[]
  ready: boolean
}

export interface RequirementCheckFuncPayload {
  eth: EthState
  wvs: WvsState
  out: number
  in: string
}

export type RequirementCheckFunc = (payload: RequirementCheckFuncPayload) => InfoItem

export const createInvalidNetworkMessage = (p: RequirementCheckFuncPayload) => {
  const expected = {
    id: p.wvs.requiredChainId,
    name: getNetworkName(p.wvs.requiredChainId)
  }
  const got = {
    id: p.wvs.publicState?.network?.code ?? 'empty',
    name: getNetworkName(p.wvs.publicState?.network?.code ?? 'empty')
  }

  return `Selected network is not valid. Expected ${expected.name} (${expected.id}). Got ${got.name} (${got.id}). Change your network in SkeyKeeper.`
}

const requirementCheckFunctions: RequirementCheckFunc[] = [
  (p) => {
    if (p.eth.metamask.status === 'connected') {
      return { status: 'success', text: 'Metamask is connected' }
    } else {
      return { status: 'error', text: 'Metamask is not connected' }
    }
  },
  (p) => {
    if (p.wvs.status === 'connected') {
      return { status: 'success', text: 'Keeper is connected' }
    } else {
      return { status: 'error', text: 'Keeper is not connected' }
    }
  },
  (p) => {
    if (p.eth.config.chainId.toString(16) === p.eth.metamask.chainId?.replace('0x', '')) {
      return { status: 'success', text: 'ETH chainId is valid' }
    } else {
      return { status: 'error', text: 'ETH chainId is not valid' }
    }
  },
  (p) => {
    if (
      (p.wvs.dappBalance ?? 0) >= p.out * 10 ** 8 ||
      p.wvs.requiredChainId !== p.wvs.publicState?.network?.code
    ) {
      return { status: 'success', text: 'Oracle has enough tokens' }
    } else {
      return { status: 'error', text: 'Oracle does not have enough tokens' }
    }
  },
  (p) => {
    if (p.eth.userTokenBalance >= BigInt(Math.floor(Number(p.in) * 10 ** 8))) {
      return { status: 'success', text: 'User has enough tokens' }
    } else {
      return { status: 'error', text: 'User does not have enough tokens' }
    }
  },
  (p) => {
    if (p.eth.oracleStatus === 'active') {
      return { status: 'success', text: 'Oracle is active' }
    } else {
      return { status: 'error', text: 'Oracle is not active' }
    }
  },
  (p) => {
    if (Number(p.in) >= 1) {
      return { status: 'success', text: 'Input value is valid' }
    } else {
      return { status: 'error', text: 'Input value is not valid' }
    }
  },
  (p) => {
    if (p.wvs.requiredChainId === p.wvs.publicState?.network?.code) {
      return { status: 'success', text: 'Selected network is valid' }
    } else {
      return {
        status: 'error',
        text: createInvalidNetworkMessage(p)
      }
    }
  }
]

export const useInfo = (): Info => {
  const eth = useEth()
  const wvs = useWvs()
  const { output, input } = useInput()

  const messages = requirementCheckFunctions.map((func) =>
    func({ eth, wvs, out: output, in: input })
  )

  const ready = messages.every((message) => message.status === 'success')

  return { messages, ready }
}
