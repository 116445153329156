import React from 'react'
import { Button } from '@mui/material'
import { BlockchainWalletStatus } from '../Common/Interfaces'

export interface WalletButtonProps {
  name: string
  status: BlockchainWalletStatus
  connect: () => Promise<any>
}

const getText = (status: BlockchainWalletStatus, name: string) => {
  if (status === 'connected') return `${name} connected`
  if (status === 'connecting') return 'Connecting...'
  if (status === 'initializing') return 'Initializing...'
  if (status === 'notConnected') return `Connect ${name}`
  if (status === 'unavailable') return `${name} unavailable`

  return ''
}

const isDisabled = (status: BlockchainWalletStatus) => {
  return status !== 'notConnected'
}

const WalletButton: React.FC<WalletButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      sx={{ marginRight: '10px' }}
      size="small"
      disabled={isDisabled(props.status)}
      onClick={props.connect}
    >
      {getText(props.status, props.name)}
    </Button>
  )
}

export default WalletButton
