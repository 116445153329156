import React from 'react'

export interface ExchangeConfig {
  url: string
  tokenName: string
}

export interface ExchangeProviderProps {
  config: ExchangeConfig
}

export interface ExchangeState {
  config: ExchangeConfig
  price: number | null
}

export const ExchangeContext = React.createContext<ExchangeState>(null as any)

export const fetchPrice = async (config: ExchangeConfig) => {
  const url = `${config.url}/${config.tokenName}/tickers?page=1&depth=false`

  try {
    const res = await fetch(url)
    const data = await res.json()
    const value: number | null = data?.tickers?.[0]?.converted_last?.usd ?? null
    return value
  } catch (e) {
    console.error(e)
    return null
  }
}

export const ExchangeProviderState: React.FC<ExchangeProviderProps> = (props) => {
  const [price, setPrice] = React.useState<number | null>(null)

  const state: ExchangeState = {
    config: props.config,
    price
  }

  React.useEffect(() => {
    fetchPrice(props.config).then(setPrice)

    // eslint-disable-next-line
  }, [])

  return (
    <ExchangeContext.Provider value={state}>{props.children}</ExchangeContext.Provider>
  )
}

export const ExchangeProvider: React.FC<ExchangeProviderProps> = (props) => {
  return <ExchangeProviderState {...props}>{props.children}</ExchangeProviderState>
}

export const useExchange = () => React.useContext(ExchangeContext)
