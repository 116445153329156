import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Alert,
  Link
} from '@mui/material'
import { SwapState } from '../Hooks/useSwap'

export interface ErrorDialogProps {
  swap: SwapState
}

const Line: React.FC = (props) => {
  return (
    <React.Fragment>
      <code style={{ wordBreak: 'break-word' }}>{props.children}</code>
      <br />
    </React.Fragment>
  )
}

const ErrorDialog: React.FC<ErrorDialogProps> = (props) => {
  return (
    <Dialog open={props.swap.status === 'failed'}>
      <DialogTitle>Critical error</DialogTitle>
      <DialogContent>
        <Alert severity="error" variant="filled" sx={{ marginBottom: 2 }}>
          <Line>Tx hash: {props.swap.context.depositTxHash ?? 'null'}</Line>
          <Line>Message:</Line>
          <Line>{props.swap.error}</Line>
        </Alert>
        <DialogContentText>
          Contact us here:{' '}
          <Link href="mailto:support@skey.network">support@skey.network</Link>
        </DialogContentText>
        <DialogActions>
          <Button onClick={props.swap.clear}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorDialog
