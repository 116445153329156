import React from 'react'
import { LoadingButton } from '@mui/lab'
import { SwapState, SwapStatus } from '../Hooks/useSwap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useInfo } from '../Hooks/useInfo'

export interface SwapButtonProps {
  swap: SwapState
  onClick?: () => void
}

const isLoading = (status: SwapStatus) => {
  return status !== 'failed' && status !== 'completed' && status !== 'ready'
}

const isDisabled = (status: SwapStatus) => {
  return status !== 'ready'
}

const text = (status: SwapStatus) => {
  if (status === 'ready') return 'Swap'
  if (status === 'completed') return 'Completed'
  if (status === 'failed') return 'Failed'

  return 'Processing'
}

const displayArrow = (status: SwapStatus) => {
  return status !== 'completed' && status !== 'failed'
}

const SwapButton: React.FC<SwapButtonProps> = (props) => {
  const { ready } = useInfo()

  return (
    <LoadingButton
      variant="contained"
      loading={isLoading(props.swap.status)}
      loadingPosition={displayArrow(props.swap.status) ? 'end' : undefined}
      endIcon={displayArrow(props.swap.status) ? <ArrowForwardIcon /> : undefined}
      onClick={props.onClick}
      disabled={isDisabled(props.swap.status) || !ready}
      fullWidth
    >
      {text(props.swap.status)}
    </LoadingButton>
  )
}

export default SwapButton
