import React from 'react'
import winston from 'winston'
import { WinstonProvider } from 'winston-react'
import { config } from '../../config'

export interface LoggerConfig {
  host: string
  port: number
  token: string
}

export const createHttpTransport = (config: LoggerConfig) => {
  return new winston.transports.Http({
    host: config.host,
    path: '/v1/log',
    auth: { username: 'whatever', password: config.token },
    level: 'debug',
    ssl: true,
    format: winston.format.combine(winston.format.timestamp(), winston.format.simple())
  })
}

export const getConfig = () => {
  const { host, port, token } = config.papertrail

  if (!Number.isSafeInteger(port)) return null
  if (!token || !host) return null

  return { host, port, token }
}

export const createInstance = () => {
  const config = getConfig()
  const transports = config ? [createHttpTransport(config)] : []

  return winston.createLogger({ transports })
}

export const LoggerProvider: React.FC = (props) => {
  const logger = React.useRef(createInstance())

  return <WinstonProvider logger={logger.current}>{props.children}</WinstonProvider>
}
