import React from 'react'
import { TextField } from '@mui/material'

export interface InputValueFieldProps {
  value: string
  setValue: (value: string) => void
}

const validate = (value: string, decimals: number): string | null => {
  if (value === '') return 'Empty value'

  const num = Number(value)

  if (!Number.isFinite(num)) {
    return 'Number is invalid'
  }

  if (num === 0) {
    return 'Amount is 0'
  }

  if (num < 0) {
    return 'Number is negative'
  }

  if (value.split('.')[1]?.length > decimals) {
    return `Maximal precission is ${decimals}`
  }

  if (num < 1) {
    return 'Minimal swap value is 1'
  }

  return null
}

const InputValueField: React.FC<InputValueFieldProps> = (props) => {
  const error = validate(props.value, 8)

  return (
    <TextField
      value={props.value}
      onChange={(e) => props.setValue(e.target.value)}
      variant="outlined"
      label="Input value"
      fullWidth
      error={!!error}
      helperText={error}
      type="number"
      placeholder="0"
    />
  )
}

export default InputValueField
