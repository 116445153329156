export const NETWORKS = Object.freeze([
  { chainId: 'R', name: 'Custom' },
  { chainId: 'A', name: 'Testnet' },
  { chainId: 'M', name: 'Mainnet' },
  { chainId: 'D', name: 'Devnet' }
])

export const getNetworkName = (chainId: string) => {
  return NETWORKS.find((network) => network.chainId === chainId)?.name ?? 'Unknown'
}
