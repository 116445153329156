import React from 'react'
import { BlockchainWalletStatus } from '../../Common/Interfaces'
import { useBalance } from '../../Hooks/useBalance'
import { WvsService } from './WvsService'

export interface WvsConfig {
  connectOnLoad: boolean
  requiredChainId: string
  dappAddress: string
  returnTxTimeout: number
}

export interface WvsProviderProps {
  config: WvsConfig
}

export interface WvsState {
  status: BlockchainWalletStatus
  dappBalance: number | null
  publicState: WavesKeeper.IPublicStateResponse | null
  service: WvsService
  connect: () => Promise<void>
  requiredChainId: string
  userBalance: number
  forceUserBalanceUpdate: () => void
}

export const WvsContext = React.createContext<WvsState>(null as any)

export const WvsProvider: React.FC<WvsProviderProps> = (props) => {
  const [status, setStatus] = React.useState<BlockchainWalletStatus>('initializing')
  const [dappBalance, setDappBalance] = React.useState<number | null>(null)
  const [publicState, setPublicState] =
    React.useState<WavesKeeper.IPublicStateResponse | null>(null)
  const service = React.useRef(new WvsService(props.config.dappAddress)).current
  const { balance: userBalance, forceUpdate: forceUserBalanceUpdate } = useBalance(
    service,
    publicState?.account?.address
  )

  React.useEffect(() => {
    setStatus('connecting')

    service.waitForKeeper(2000).then((result) => {
      setStatus(result ? 'notConnected' : 'unavailable')
      if (props.config.connectOnLoad) connect()
    })

    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!publicState?.network?.server) return

    service.getBalance(service.dappAddress).then(setDappBalance)

    // eslint-disable-next-line
  }, [publicState?.network?.server])

  const connect = async () => {
    const keeper = service.keeper
    if (!keeper) return

    const result = await keeper.publicState().catch((e) => {
      if (e?.code === '14') alert('Add first account to Skey Keeper')
      console.error(e)
      return undefined
    })

    setStatus(result ? 'connected' : 'notConnected')

    handleUpdate(result)
    keeper.on('update', handleUpdate)
  }

  const handleUpdate = (state?: WavesKeeper.IPublicStateResponse) => {
    service.setPublicState(state)
    setPublicState(state ?? null)
  }

  const state: WvsState = {
    status,
    publicState,
    service,
    connect,
    requiredChainId: props.config.requiredChainId,
    dappBalance,
    userBalance,
    forceUserBalanceUpdate
  }

  return <WvsContext.Provider value={state}>{props.children}</WvsContext.Provider>
}

export const useWvs = () => React.useContext(WvsContext)
