import React from 'react'
import { WvsService } from '../Providers/Wvs/WvsService'

export const useBalance = (service?: WvsService, address?: string) => {
  const [balance, setBalance] = React.useState(0)

  const forceUpdate = async () => {
    if (!address || !service) return

    const balance = await service.getBalance(address)
    setBalance(balance ?? 0)
  }

  React.useEffect(() => {
    forceUpdate()

    const handle = setInterval(() => {
      forceUpdate()
    }, 4000)

    return () => clearInterval(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, address])

  return { balance, forceUpdate }
}
