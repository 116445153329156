import React from 'react'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { useEth } from '../Providers/Eth/EthProvider'
import { useWvs } from '../Providers/Wvs/WvsProvider'
import WalletButton from './WalletButton'

const Navbar: React.FC = () => {
  const eth = useEth()
  const wvs = useWvs()

  const style: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
    flexGrow: 1
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box style={style} mr={3}>
            <img
              src="/assets/images/logo.svg"
              alt="logo"
              style={{ width: 40, marginRight: 12 }}
            />
            <Typography variant="h6">Skey Bridge</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} marginTop={2} marginBottom={2}>
            <Box display="flex" alignItems="center">
              <WalletButton
                name="Metamask"
                status={eth.metamask.status}
                connect={eth.metamask.connect}
              />
            </Box>

            <Box display="flex" alignItems="center">
              <WalletButton name="SkeyKeeper" status={wvs.status} connect={wvs.connect} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Navbar
