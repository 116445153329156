import { useWinstonLogger } from 'winston-react'
import { config } from '../config'

export const useLogger = () => {
  const raw = useWinstonLogger()

  const { context } = config.papertrail

  return {
    info: (message: string, details: Record<string, any> = {}) =>
      raw.info(message, { ...details, context }),
    error: (message: string, details?: Record<string, any>) =>
      raw.error(message, { ...details, context })
  }
}
