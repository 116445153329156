import React from 'react'
import { useExchange } from '../Exchange/ExchangeProvider'

export interface InputState {
  input: string
  setInput: (val: string) => void
  output: number
}

export interface InputProps {
  initialValue: string
}

export const InputContext = React.createContext<InputState>(null as any)

const calculateOutputValue = (input: string, price: number | null) => {
  const num = Number(input)
  if (!Number.isFinite(num)) return 0
  if (!price) return 0

  const result = num * price

  return Number.isFinite(result) ? result : 0
}

export const InputProvider: React.FC<InputProps> = (props) => {
  const { price } = useExchange()
  const [input, setInput] = React.useState<string>(props.initialValue)

  const output = calculateOutputValue(input, price)

  const setInputWithMaxLength = (str: string) => setInput(str.slice(0, 25))

  const state: InputState = { input, setInput: setInputWithMaxLength, output }

  return <InputContext.Provider value={state}>{props.children}</InputContext.Provider>
}

export const useInput = () => React.useContext(InputContext)
