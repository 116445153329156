import React from 'react'
import { Box, Typography } from '@mui/material'
import CardWrapper from '../Components/CardWrapper'
import StatusIcon from '../Components/StatusIcon'
import { useInfo } from '../Hooks/useInfo'

const InfoBox: React.FC = () => {
  const { messages } = useInfo()

  return (
    <CardWrapper>
      {messages.map(({ status, text }) => (
        <Box key={text}>
          <StatusIcon status={status} />
          <Typography variant="body2" component="span">
            {text}
          </Typography>
        </Box>
      ))}
    </CardWrapper>
  )
}

export default InfoBox
