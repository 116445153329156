import * as Transactions from '@waves/waves-transactions'
import { config } from '../../config'

export class WvsService {
  publicState?: WavesKeeper.IPublicStateResponse
  dappAddress: string

  constructor(dappAddress: string) {
    this.dappAddress = dappAddress
  }

  public setPublicState(state?: WavesKeeper.IPublicStateResponse) {
    this.publicState = state
  }

  get nodeUrl() {
    const url = this.publicState?.network?.server?.trimEnd() ?? null
    if (!url) return null

    return url.slice(-1) === '/' ? url.slice(0, -1) : url
  }

  get keeper() {
    const skeyKeeper = (window as any).SkeyKeeper as WavesKeeper.TWavesKeeperApi
    const wavesKeeper = window.WavesKeeper

    if (skeyKeeper) return skeyKeeper
    if (wavesKeeper) return wavesKeeper

    return null
  }

  async waitForTx(txId: string) {
    await Transactions.waitForTx(txId, { apiBase: this.nodeUrl ?? '' })
  }

  delay(n: number) {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, n)
    })
  }

  async getBalance(address: string): Promise<number | null> {
    const path = `/addresses/balance/${address}`
    const data = await this.request(path)

    return data?.balance ?? null
  }

  async getDataEntry(key: string, address: string) {
    const path = `/addresses/data/${address}/${key}`
    return await this.request(path)
  }

  private async request(path: string) {
    try {
      const url = `${this.nodeUrl}${path}`
      const res = await fetch(url)
      const data = await res.json()
      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // Returns true if WavesKeeper is ready
  // and false if timeout was reached
  async waitForKeeper(timeout: number) {
    const interval = 100
    const start = Date.now()

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        if (this.keeper?.initialPromise) {
          await this.keeper.initialPromise

          clearInterval(handle)
          return resolve(true)
        }

        const timeDiff = Date.now() - start

        if (timeDiff >= timeout) {
          clearInterval(handle)
          return resolve(false)
        }
      }, interval)
    })
  }

  waitForReturnTx(id: string, interval = 4000, timeout = 120000) {
    const start = Date.now()

    return new Promise<boolean>((resolve) => {
      const handle = setInterval(async () => {
        const entry = await this.getDataEntry(id, config.wvs.dappAddress)
        const processed = entry?.type === 'boolean' && entry?.value === true

        if (processed) {
          clearInterval(handle)
          resolve(true)
        }

        if (Date.now() >= start + timeout) {
          clearInterval(handle)
          resolve(false)
        }
      }, interval)
    })
  }
}
