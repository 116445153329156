import type { TransactionConfig, TransactionReceipt } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import Web3 from 'web3'
import { EthConfig } from './EthProvider'
import TokenAbi from './TokenAbi'
import { utils, BigNumber, ethers } from 'ethers'

export interface ContractCallInput {
  contract: Contract
  method: string
  args?: any[]
}

export interface BurnTxDataInput {
  value: BigNumber
  oracleId: string
  wvsAddress: string
}

export class EthService {
  static TRANSFER_FUNC_SIGNATURE = '0xa9059cbb'
  private provider = () => (globalThis as any).ethereum
  private config: EthConfig
  private web3: Web3
  private tokenContract: Contract

  constructor(config: EthConfig) {
    this.config = config
    this.web3 = new Web3(config.rpcUrl)
    this.tokenContract = this.initContract(TokenAbi, this.config.tokenAddress)
  }

  get eth() {
    if (!this.provider()) throw new Error('provider not available')

    return this.provider()
  }

  get address(): string | null {
    return this.eth.selectedAddress ?? null
  }

  async balance(addr: string) {
    return await this.web3.eth.getBalance(addr)
  }

  async userTokenBalance() {
    const res: string = await this.tokenContract.methods
      .balanceOf(this.address)
      .call()
      .catch((e: any) => {
        console.error(e)
        return 0n
      })

    return BigInt(res)
  }

  base58AddressToBytes32(address: string) {
    return utils.hexlify(utils.zeroPad(utils.base58.decode(address), 32))
  }

  packBurnTxData(input: BurnTxDataInput) {
    const params = ethers.utils.defaultAbiCoder
      .encode(
        ['address', 'uint256', 'bytes32', 'bytes32'],
        [
          ethers.constants.AddressZero,
          input.value,
          input.oracleId,
          this.base58AddressToBytes32(input.wvsAddress)
        ]
      )
      .substring(2)

    return `${EthService.TRANSFER_FUNC_SIGNATURE}${params}`
  }

  async createContractCallTx(value: bigint, wvsAddress: string) {
    const params: TransactionConfig = {
      to: this.tokenContract.options.address,
      from: this.address ?? undefined,
      data: this.packBurnTxData({
        value: BigNumber.from(value),
        oracleId: this.config.oracleId,
        wvsAddress
      })
    }

    const gas = await this.estimateGas(params)

    return { ...params, gas }
  }

  private async estimateGas(params: TransactionConfig) {
    return await this.web3.eth
      .estimateGas(params)
      .then((r) => r.toString(16))
      .catch((e) => {
        console.error(e)
        console.error('invalid gas estimation, using default')
        return undefined
      })
  }

  async estimateInitialGas() {
    const gasPrice = await this.web3.eth.getGasPrice().then((r) => Number(r))
    return (21000 * gasPrice) / 10 ** 18
  }

  private initContract(abi: any, address: string) {
    return new this.web3.eth.Contract(abi, address)
  }

  waitForTx(txHash: string) {
    const INTERVAL = 2000

    return new Promise<TransactionReceipt>((resolve) => {
      const handle = setInterval(async () => {
        const tx = await this.web3.eth.getTransactionReceipt(txHash).catch(() => null)

        if (!tx?.blockHash || !tx?.status) return

        clearInterval(handle)
        resolve(tx)
      }, INTERVAL)
    })
  }

  getErrorMessage(err: any): string {
    const prefix = 'Returned error: '

    if (err?.message?.includes(prefix)) {
      return err.message.replace(prefix, '')
    }

    if (err?.message) return err.message

    return 'unknown_error'
  }

  async sendTx(params: TransactionConfig): Promise<string> {
    return await this.eth.request({
      method: 'eth_sendTransaction',
      params: [{ ...params }]
    })
  }
}
