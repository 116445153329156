import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'

export interface StatusIconProps {
  status: 'success' | 'error'
}

const StatusIcon: React.FC<StatusIconProps> = (props) => {
  const style: React.CSSProperties = {
    marginRight: 10,
    fontSize: 20,
    transform: 'translateY(5px)'
  }

  if (props.status === 'success') {
    return <CheckIcon color="success" style={style} />
  }

  if (props.status === 'error') {
    return <ErrorIcon color="error" style={style} />
  }

  return null
}

export default StatusIcon
