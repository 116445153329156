import React from 'react'
import { Paper } from '@mui/material'

const CardWrapper: React.FC = (props) => {
  return (
    <Paper
      elevation={5}
      sx={{
        width: '100%',
        maxWidth: '350px',
        padding: '20px 15px',
        margin: '10px',
        height: 'min-height'
      }}
    >
      {props.children}
    </Paper>
  )
}

export default CardWrapper
