import { createTheme, ThemeOptions } from '@mui/material/styles'
import { ThemeProvider as RawThemeProvider } from '@mui/system'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#5c44ec'
    },
    secondary: {
      main: '#eca344'
    }
  },
  typography: {
    fontSize: 16
  }
}

export const theme = createTheme(themeOptions)

const ThemeProvider: React.FC = (props) => {
  return <RawThemeProvider theme={theme}>{props.children}</RawThemeProvider>
}

export default ThemeProvider
