import React from 'react'
import { EthState, useEth } from '../Providers/Eth/EthProvider'
import { useWvs, WvsState } from '../Providers/Wvs/WvsProvider'
import { useInfo } from './useInfo'
import { useLogger } from './useLogger'

const wvsStateLog = (wvs: WvsState) => ({
  account: wvs.publicState?.account ?? {},
  network: wvs.publicState?.network ?? {},
  status: wvs.status
})

const ethStateLog = (eth: EthState) => ({
  oracleStatus: eth.oracleStatus,
  userTokenBalance: eth.userTokenBalance?.toString(),
  account: eth.metamask?.account,
  chainId: eth.metamask?.chainId,
  stauts: eth.metamask?.status
})

export const useStateLogger = () => {
  const logger = useLogger()
  const eth = useEth()
  const wvs = useWvs()
  const { messages } = useInfo()

  React.useEffect(() => {
    const handle = setTimeout(() => {
      const errors = messages.filter((msg) => msg.status === 'error')
      if (!errors.length) return

      logger.error('app state', {
        errors,
        wvsState: wvsStateLog(wvs),
        ethState: ethStateLog(eth)
      })
    }, 3000)

    return () => clearTimeout(handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
