import { Config } from './Common/Interfaces'

const getEnv = (path: string) => process.env[`REACT_APP_${path}`]!

export const config: Config = {
  input: {
    initialValue: '1'
  },
  eth: {
    rpcUrl: getEnv('ETH_RPC_URL'),
    tokenAddress: getEnv('ETH_TOKEN_ADDRESS'),
    oracleId: getEnv('ETH_ORACLE_ID'),
    chainId: Number(getEnv('ETH_CHAIN_ID') ?? '4'),
    oracleUrl: getEnv('ETH_ORACLE_URL')
  },
  wvs: {
    connectOnLoad: true,
    requiredChainId: getEnv('WVS_REQUIRED_CHAIN_ID') ?? 'R',
    dappAddress: getEnv('WVS_DAPP_ADDRESS'),
    returnTxTimeout: 120000
  },
  exchange: {
    url: 'https://api.coingecko.com/api/v3/coins',
    tokenName: 'skey-network'
  },
  papertrail: {
    host: getEnv('PAPERTRAIL_HOST') ?? 'logs.collector.solarwinds.com',
    port: Number(getEnv('PAPERTRAIL_PORT') ?? '443'),
    token: getEnv('PAPERTRAIL_TOKEN'),
    context: getEnv('PAPERTRAIL_CONTEXT') ?? 'Unknown'
  }
}

const checkNull = (arr: any[]) => {
  for (const item of arr) {
    if (item === null || item === undefined) {
      console.error(`Invalid config value => ${typeof item}`)
    }
  }
}

;(globalThis as any).config = config

checkNull([
  config.eth.rpcUrl,
  config.eth.oracleId,
  config.eth.tokenAddress,
  config.eth.oracleUrl,
  config.wvs.dappAddress,
  config.papertrail.token
])
