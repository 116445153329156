import React from 'react'
import CardWrapper from '../Components/CardWrapper'
import InputValueField from '../Components/InputValueField'
import { TextField, Typography } from '@mui/material'
import Space from '../Components/Space'
import { useSwap } from '../Hooks/useSwap'
import TransactionSteps from '../Components/TransactionSteps'
import SwapButton from '../Components/SwapButton'
import ConfirmationDialog from '../Components/ConfirmationDialog'
import { useInput } from '../Providers/Input/InputProvider'
import { useEth } from '../Providers/Eth/EthProvider'
import ErrorDialog from '../Components/ErrorDialog'

const Form: React.FC = () => {
  const [dialog, setDialog] = React.useState(false)
  const [fee, setFee] = React.useState<number | null>(null)
  const { input, setInput, output } = useInput()
  const swap = useSwap()
  const { service: ethService, metamask } = useEth()

  React.useEffect(() => {
    if (!metamask.account) return

    ethService.estimateInitialGas().then((gas) => setFee(gas ?? null))

    // eslint-disable-next-line
  }, [metamask.account])

  return (
    <CardWrapper>
      <Typography variant="h5" gutterBottom>
        Swap
      </Typography>
      <Space />
      {swap.status === 'ready' && (
        <React.Fragment>
          <InputValueField value={input} setValue={setInput} />
          <Space />
          <TextField
            value={output.toFixed(4)}
            variant="outlined"
            label="Output value"
            fullWidth
            placeholder="0"
            disabled
          />
          <Space />
          <SwapButton swap={swap} onClick={() => setDialog(true)} />
          <Space />
        </React.Fragment>
      )}
      {swap.status !== 'ready' && <TransactionSteps swap={swap} />}
      <ConfirmationDialog
        open={dialog}
        onContinue={() => swap.init(input)}
        onClose={() => setDialog(false)}
        fee={fee}
      />
      <ErrorDialog swap={swap} />
    </CardWrapper>
  )
}

export default Form
