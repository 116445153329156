import React from 'react'
import { Grid } from '@mui/material'
import Form from './Views/Form'
import Navbar from './Components/Navbar'
import InfoBox from './Views/InfoBox'
import ExpensesExample from './Views/ExpensesExample'
import WalletCard from './Components/WalletCard'
import { useStateLogger } from './Hooks/useStateLogger'

const Home: React.FC = () => {
  useStateLogger()

  return (
    <React.Fragment>
      <Navbar />
      <Grid
        container
        spacing={0}
        sx={(theme) => ({
          paddingTop: '70px',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: { paddingTop: '20px' }
        })}
      >
        <WalletCard bc={'eth'} />
        <WalletCard bc={'eskey'} />
        <WalletCard bc={'skey'} />
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{
          paddingTop: '0px',
          paddingBottom: '50px',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Form />
        <InfoBox />
        <ExpensesExample />
      </Grid>
    </React.Fragment>
  )
}

export default Home
