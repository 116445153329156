import React from 'react'
import { EthProvider } from './Providers/Eth/EthProvider'
import ThemeProvider from './Providers/Theme/ThemeProvider'
import { WvsProvider } from './Providers/Wvs/WvsProvider'
import Home from './Home'
import { InputProvider } from './Providers/Input/InputProvider'
import { ExchangeProvider } from './Providers/Exchange/ExchangeProvider'
import { config } from './config'
import { LoggerProvider } from './Providers/Logger/LoggerProvider'

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <LoggerProvider>
        <ExchangeProvider config={config.exchange}>
          <EthProvider config={config.eth}>
            <WvsProvider config={config.wvs}>
              <ThemeProvider>
                <InputProvider initialValue={config.input.initialValue}>
                  <Home />
                </InputProvider>
              </ThemeProvider>
            </WvsProvider>
          </EthProvider>
        </ExchangeProvider>
      </LoggerProvider>
    </React.StrictMode>
  )
}

export default App
